import defaultLogo from 'assets/logo.svg';
import { useRealmContext } from 'context/RealmContext';
import { useStorroApi } from 'context/StorroApiContext';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Realm } from 'types';
import { logger } from 'util/Logger';

export default function useRealm(): {
  loadRealms: () => Promise<Realm[]>;
  loadRealm: (realmId: number, signal?: AbortSignal) => Promise<void>;
  realm: Realm | undefined;
  realms: Realm[];
  setRealms: Dispatch<SetStateAction<Realm[]>>;
  setRealm: Dispatch<SetStateAction<Realm | undefined>>;
  loading: boolean;
  realmCount: number | undefined;
  initialLoaded: boolean;
  realmLogo: string; // this is either the custom logo or the Storro fallback logo
  realmCustomLogo: string | undefined;
} {
  const [loading, setLoading] = useState<boolean>(false);
  const [customLogo, setCustomLogo] = useState<string>();

  const { storroApi } = useStorroApi();
  const { realm, realms, setRealm, setRealms, setRealmCount, realmCount, initialLoaded, setInitialLoaded, setRealmLogo, realmLogo } =
    useRealmContext();

  /**
   * Load all realms
   */
  const loadRealms = useCallback(async () => {
    setLoading(true);
    try {
      const realms = await storroApi.listRealms();
      setRealms(realms);
      setRealmCount(realms.length);
      setInitialLoaded(true);

      return Promise.resolve(realms);
    } catch (e) {
      setRealms([]);
      setRealm(undefined);
      setRealmCount(0);
      logger.error(e);
      return Promise.reject();
    } finally {
      setLoading(false);
    }
  }, [setInitialLoaded, setRealm, setRealmCount, setRealms, storroApi]);

  /**
   * Load a single realm
   */
  const loadRealm = useCallback(
    async (realmId: number, signal?: AbortSignal) => {
      setLoading(true);
      try {
        const realm = await storroApi.listRealm(realmId, signal);
        if (!signal?.aborted) {
          setRealm(realm);
        }

        return Promise.resolve();
      } catch (e) {
        if (!signal?.aborted) {
          logger.error(e);
          return Promise.reject();
        }
      } finally {
        setLoading(false);
      }
    },
    [setRealm, storroApi],
  );

  /**
   * Check if a custom realm logo exists, if so set this as the logo
   */
  useEffect(() => {
    if (realm && realm.logoHash) {
      const customLogo = storroApi.getLogoUrl(realm.id, realm.logoHash);
      setCustomLogo(customLogo);
      setRealmLogo(customLogo);
    } else {
      setCustomLogo(undefined);
      setRealmLogo(defaultLogo);
    }
  }, [realm, setRealmLogo, storroApi]);

  return {
    loadRealms,
    loadRealm,
    realm,
    realms,
    setRealm,
    setRealms,
    loading,
    realmCount,
    initialLoaded,
    realmLogo: realmLogo ?? defaultLogo,
    realmCustomLogo: customLogo,
  };
}
