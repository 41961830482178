import { Placement } from '@popperjs/core/lib/enums';
import classNames from 'classnames';
import React, { ReactNode, useEffect, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { usePopper } from 'react-popper';
import useIsMobileDevice from '../../hooks/UseIsMobileDevice';
import Tooltip, { TooltipProps } from '../Tooltip';
import { Portal } from 'react-portal';
import { IS_MOBILE_APP } from 'const';

// this is the time for the delay and duration of the animation
const DELAY_MS = 400;

// eslint-disable-next-line
export interface DropdownMenuItem {
  element: ReactNode;
  isHeader?: boolean;
  onClick?: () => void;
  isVisible?: boolean | (() => boolean);
  isDisabled?: boolean;
  tooltip?: TooltipProps;
  className?: string;
}

// eslint-disable-next-line
export type DropdownMenuItemArray = DropdownMenuItem[];

interface Props {
  disabled?: boolean;
  buttonClassName?: string;
  ulClassName?: string;
  className?: string;
  paddingSize?: 'base' | 'sm' | 'xs';
  menuItems: DropdownMenuItemArray[];
  children?: ReactNode;
  menuPlacement?: Placement;
}

/**
 * The dropdown component create a clickable element with a list of items that are visible after the clickable element
 * received a click event.
 *
 * Example:
 * <Dropdown
 *    menuItems={[
 *    [
 *      { data: 'type of the action, or any value you need to pass', element: <div>Item 1</div>, onClick: () => void, visible: true },
 *      { data: 'type of the action, or any value you need to pass', element: <div>Item 2</div>, onClick: () => void, visible: () => true },
 *    ],
 *    [
 *      { type: 'type of the action, or any value you need to pass', element: <div>Item 5</div>, onClick: () => void },
 *    ],
 *  ]}
 * </Dropdown>
 */
export default function DropdownMenu({
  children,
  menuItems,
  buttonClassName,
  ulClassName,
  className,
  disabled = false,
  paddingSize = 'base',
  menuPlacement = 'auto',
}: Props): JSX.Element {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  // flag that indicate if the dropdownMenu is visible
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [startCloseMobileAnimation, setStartCloseMobileAnimation] = useState<boolean>(false);

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(referenceElement, popperElement, {
    placement: menuPlacement,
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          altAxis: true,
          padding: 20,
        },
      },
    ],
  });
  const isMobileDevice = useIsMobileDevice();

  // set the animation classes for our mobile view
  const mobileAnimationClass = !startCloseMobileAnimation ? 'animate-slideInUp' : 'animate-slideOutDown';
  const mobileAnimationBgClass = !startCloseMobileAnimation ? 'animate-fadeIn' : 'animate-fadeOut';

  /**
   * Delay the visible flag so we can show the actual animation to our viewer
   * This only apply for a mobile device
   */
  useEffect(() => {
    // bail out if this is not an mobile device
    if (!isMobileDevice) return;

    if (startCloseMobileAnimation) {
      setTimeout(() => {
        setIsVisible(false);
        setStartCloseMobileAnimation(false);
      }, DELAY_MS);
    }
  }, [isMobileDevice, isVisible, startCloseMobileAnimation]);

  /**
   * Render a single line
   */
  const renderLine = (key: string) => {
    return (
      <li
        key={key}
        className={classNames('my-1 bg-gray-200', {
          hidden: isMobileDevice,
        })}
        style={{ height: 1 }}
      />
    );
  };

  /**
   * Render a single item
   */
  const renderHeader = ({
    label,
    key,
    icon,
    onClick,
  }: {
    label: ReactNode;
    key?: string;
    icon?: JSX.Element;
    onClick?: () => void;
  }): ReactNode | undefined => {
    return (
      <li
        key={key}
        className={classNames('pt-2 pb-3 w-full font-bold flex items-center justify-between border-b', {
          'px-6': paddingSize === 'base',
          'px-4': paddingSize === 'sm',
          'px-1': paddingSize === 'xs',
          '!py-4 !justify-center bg-gray-100': isMobileDevice,
        })}
      >
        <div className='truncate'>{label}</div>
        <button
          onClick={() => {
            closeMenu();
            onClick?.();
          }}
        >
          {icon}
        </button>
      </li>
    );
  };

  /**
   * Render a single item
   */
  const renderItem = (item: DropdownMenuItem, key: string): ReactNode | undefined => {
    // check if the item should be visible
    let isVisible = true;
    if (typeof item.isVisible === 'function') {
      isVisible = item.isVisible();
    } else if (typeof item.isVisible === 'boolean') {
      isVisible = item.isVisible;
    }

    // not visible, bail out
    if (!isVisible) return undefined;

    // Render as header item
    if (item.isHeader) {
      return renderHeader({
        key,
        label: item.element,
        onClick: item.onClick,
      });
    }

    return (
      <li key={key} className={classNames({ 'border-b last:border-0': isMobileDevice })}>
        <Tooltip disabled={!item.tooltip} {...item.tooltip}>
          <a
            href='#'
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();

              // fire the onClick if the item is not disabled
              if (!item.isDisabled) {
                item.onClick?.();

                // after a click we close the dropDownMenu
                closeMenu();
              }
            }}
            className={classNames('py-2 w-full inline-block hover:bg-gray-50 md:hover:bg-gray-100', item.className, {
              'cursor-default': item.onClick === undefined,
              'px-6': paddingSize === 'base',
              'px-4': paddingSize === 'sm',
              'px-1': paddingSize === 'xs',
              '!text-gray-400 !cursor-not-allowed': item.isDisabled,
              'py-4 text-center !text-lg text-primary': isMobileDevice,
            })}
          >
            {item.element}
          </a>
        </Tooltip>
      </li>
    );
  };

  /**
   * Button click event
   */
  const buttonClick = () => {
    if (!disabled) {
      setIsVisible(prevState => !prevState);
    }
  };

  /**
   * Close the menu by setting the active state to false
   */
  const closeMenu = () => {
    // for a mobile device we close the dropdown with animation
    if (isMobileDevice) {
      setStartCloseMobileAnimation(true);
    } else {
      setIsVisible(false);
    }
  };

  return (
    <>
      {/* Render the trigger button */}
      <div
        ref={setReferenceElement}
        onClick={buttonClick}
        className={classNames(buttonClassName, {
          'cursor-default': disabled,
        })}
      >
        {children}
      </div>

      {/* If active we should render the items */}
      {isVisible && (
        <Portal>
          {/* For web we render the items directly next to the trigger element */}
          {!isMobileDevice && (
            <ClickAwayListener onClickAway={closeMenu}>
              <div
                ref={setPopperElement}
                style={popperStyles.popper}
                {...popperAttributes.popper}
                className={classNames('z-[999]', className)}
              >
                <ul className={classNames('bg-white border border-gray-300 p-2 w-50 rounded shadow-md text-sm', ulClassName)}>
                  {menuItems.map((items, rootIndex) => {
                    const renderedItems = items.reduce<ReactNode[]>((prevItem, curItem, curIndex) => {
                      // render the item to a ReactNode
                      const element = renderItem(curItem, `${rootIndex}-${curIndex}`);
                      if (element !== undefined) {
                        prevItem.push(element);
                      }

                      return prevItem;
                    }, []);

                    // render a line if needed, by checking if we are passed the first root item
                    // and by checking if the current array of items does contain items
                    if (rootIndex > 0 && renderedItems.length > 0) {
                      return [renderLine(`line-${rootIndex}`), ...renderedItems];
                    }

                    return renderedItems;
                  })}
                </ul>
              </div>
            </ClickAwayListener>
          )}

          {/* Different UI for mobile */}
          {isMobileDevice && (
            <>
              <div
                className={classNames(
                  'z-[9999997] bg-black/25 fixed top-0 left-0 w-full h-full overflow-hidden animate-fast',
                  mobileAnimationBgClass,
                )}
              />
              <div
                onDragEnter={e => {
                  // prevent any dragEvent as this break the dropdown
                  e.stopPropagation();
                }}
                className={classNames(
                  'fixed z-[9999998] w-full p-3 left-0 bottom-0 animate-fast overflow-y-scroll h-[calc(100vh-env(safe-area-inset-top))] flex flex-col justify-end gap-2',
                  // env() intial value is not working with safe-area-inset-bottom as it return 0
                  // therefor, we can use max to determing an initial value when safe-area-inset-bottom=0
                  // by using max()
                  'pb-[max(env(safe-area-inset-bottom),15px)]',
                  mobileAnimationClass,
                  className,
                  {
                    'mb-3': !IS_MOBILE_APP,
                    hidden: !isMobileDevice,
                  },
                )}
              >
                <ul className={classNames('bg-white border border-gray-300 w-50 rounded-xl overflow-hidden', ulClassName)}>
                  {menuItems.map((items, rootIndex) => {
                    const renderedItems = items.reduce<ReactNode[]>((prevItem, curItem, curIndex) => {
                      // render the item to a ReactNode
                      const element = renderItem(curItem, `${rootIndex}-${curIndex}`);
                      if (element !== undefined) {
                        prevItem.push(element);
                      }

                      return prevItem;
                    }, []);

                    return renderedItems;
                  })}
                </ul>

                {/* Render a close button */}
                <ul className={classNames('bg-white border border-gray-300 w-50 rounded-xl overflow-hidden', ulClassName)}>
                  {renderItem({ element: 'Close', onClick: () => '' }, 'close-item')}
                </ul>
              </div>
            </>
          )}
        </Portal>
      )}
    </>
  );
}
