import { DiffSummaryJson } from 'api/Diff';
import { User } from './User';

export enum ProjectPermissions {
  Administrator = 'Administrator',
  ReadWrite = 'ReadWrite',
  ReadOnly = 'ReadOnly',
  SyncOnly = 'SyncOnly', // for a PSP/POD
}

export interface ProjectMemberBadge {
  initials: string; // The initials of the user
  pubKeyTag: string; // The first 8 chars of the public key base64url
}

export interface ProjectDetails {
  id: string;
  realmId: number;
  name: string;
  description: string;
  removed: boolean;
  isQuickShare: boolean;
  streamChunkerConfig: string;
  convergentEncryptionAlgo: object; // Configuration for the convergent encryption algorithm
  hashingAlgo: object; // Configuration for the hashing algorithm
  chunkZipAlgo: object; // Configuration for the chunk zipper algorithm
  mergingAlgo: object; // Configuration for the merging algorithm
  facilitator: string;
  metaKey: string; // Base64Url of the meta data symmetric key
  metadataStoreConfig: string;
  contentStoreConfig: string;
  encryptedContentKey: string;
  permissions: ProjectPermissions;
  invitationAccepted: false;
  invitedBy: string | undefined; // By who is the user invited
  headVersionId: string; // Base64Url of the head version id
  headInSync: boolean; // True when headVersionId is identical to the head of the facilitator
  createdAt: string;
  lastModifiedAt: string;
  totalFileSize: number; // total size in bytes
  memberBadges: ProjectMemberBadge[];
  realmSalt: string; // The salt of the realm to where this project belongs.
  diff: DiffSummaryJson[] | undefined; // The diff of the project head version (undefined if the diff is not cached at the api)
}

export interface ProjectMember extends Pick<User, 'id' | 'email' | 'firstName' | 'lastName'> {
  publicKeyBase64: string;
  permissions: ProjectPermissions;
  invitationAccepted: boolean;
  isRegistered: boolean;
  isGuest: boolean;
  joinedAt?: string | null;
}

/**
 * Interface for adding a member to a project
 */
export interface ProjectMembersRequest {
  publicKeyBase64: string;
  encryptedContentKey: string;
  permission: ProjectPermissions;
}
