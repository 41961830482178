import classNames from 'classnames';
import React, { ReactNode, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import useRealm from '../../../hooks/realm/UseRealm';
import useMenu, { MenuMode } from '../../../hooks/UseMenu';
import { IconContext } from '@phosphor-icons/react';

export interface Props {
  path?: string;
  icon?: ReactNode;
  padding?: number;
  onClick?: () => void;
  disabled?: boolean;
  grayedOut?: boolean;
  className?: string;
  children: ReactNode;
}

export default function MenuItem({ path, icon, grayedOut, disabled, onClick, children, className }: Props): JSX.Element {
  const { setIsOpen, isOpen, menuMode } = useMenu();
  const { realm, realms, setRealm } = useRealm();
  const defaultWrapClasses = classNames('flex items-center pl-3 2xl:pl-8 pr-3 text-gray-500 hover:text-gray-700 py-3 border-t border-b', {
    // when the menu is open, we justify the content to the end
    // and set the padding differently
    'justify-end !pr-[13px]': !isOpen,
  });

  /**
   * callback when we click on a link
   */
  const onLinkClick = useCallback(() => {
    // we close the menu only when we are in the small mode
    menuMode === MenuMode.SMALL && setIsOpen(false);

    // if the realm has not been set, because the user comes from a page without realm context (e.g. /account)
    // we set the first realm as active
    if (!realm) {
      setRealm(realms[0]);
    }
  }, [realm, realms]); //eslint-disable-line

  /**
   * Render the inner item with or without an icon
   */
  const innerItem = (
    <>
      <IconContext.Provider value={{ size: 18, className: 'shrink-0' }}>{icon}</IconContext.Provider>
      {isOpen && <div className={classNames('mx-3 leading-6 w-full', className)}>{children}</div>}
    </>
  );

  /**
   * When we pass an PATH variable, we should render the item with a <link> component
   * Otherwise, we use a <span> with an onClick event attached to control it.
   */
  let item: React.ReactNode;
  if (path) {
    item = disabled ? (
      <span className={classNames(defaultWrapClasses, 'opacity-60 cursor-not-allowed')}>{innerItem}</span>
    ) : (
      <NavLink
        onClick={onLinkClick}
        to={path}
        className={navData =>
          classNames(defaultWrapClasses, {
            'bg-gray-50 text-gray-700 ': navData.isActive,
            'border-gray-100': !navData.isActive,
            'opacity-60': grayedOut || disabled,
          })
        }
      >
        {innerItem}
      </NavLink>
    );
  } else {
    item = (
      <span className={classNames('cursor-pointer', defaultWrapClasses)} onClick={onClick}>
        {innerItem}
      </span>
    );
  }

  return <li>{item}</li>;
}
