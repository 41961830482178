import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStorroApi } from '../../context/StorroApiContext';
import useRealm from '../../hooks/realm/UseRealm';
import { SubscriptionStatus } from '../../types/Payment';
import { logger } from '../../util/Logger';
import { friendlyStatusName } from '../../util/Util/FriendlyStatusName';
import Skeleton from '../Skeleton';

function RealmStatusBadge(): JSX.Element {
  const [trialDaysLeft, setTrialDaysLeft] = useState<number>();
  const [loaded, setLoaded] = useState<boolean>();

  const { realm, initialLoaded } = useRealm();
  const { storroApi } = useStorroApi();
  const navigate = useNavigate();
  const location = useLocation();

  // Check if we are in realm context
  const inRealmContext = realm !== undefined || location.pathname.startsWith('/realm');

  const badge = useMemo(() => {
    if (!realm) return;

    let badgeContent: React.ReactNode;
    let badgeType: 'danger' | 'default' = 'default';

    if (realm.status === SubscriptionStatus.Cancelled) {
      badgeType = 'danger';
      badgeContent = 'Cancelled';
    } else if (realm.status === SubscriptionStatus.Trial) {
      badgeType = 'default';
      badgeContent = (
        <>
          {friendlyStatusName(realm.status)} (<strong>{trialDaysLeft}</strong> days left)
        </>
      );
    } else if (realm.status === SubscriptionStatus.TrialExpired) {
      badgeContent = 'Trial expired!';
    } else if (realm.status === SubscriptionStatus.Active && realm.needPayment) {
      badgeType = 'danger';
      badgeContent = 'Payment details missing';
    }

    return {
      content: badgeContent,
      type: badgeType,
    };
  }, [realm, trialDaysLeft]);

  /**
   * Fetch the realm status and set get trial days if the realm is in Trial
   */
  useEffect(() => {
    const abortController = new AbortController();

    if (realm) {
      if (realm.status === SubscriptionStatus.Trial && realm.isAdmin) {
        storroApi
          .getRealmTrialStatus(realm.id, abortController.signal)
          .then(res => {
            setLoaded(true);
            setTrialDaysLeft(res.trialDaysLeft);
          })
          .catch(error => {
            if (!abortController.signal.aborted) {
              logger.warn('Cannot fetch the Trial days left of the current realm', error);
            }
          });
      } else {
        setLoaded(true);
      }
    } else if (initialLoaded) {
      // when the user is not tied to a realm, the realm property is undefined
      // therefor we should check upon the initialLoaded state to see if we
      // did finish the API call to load the realms
      setLoaded(true);
    }

    return () => abortController.abort();
  }, [initialLoaded, realm, storroApi]);

  // Return nothing when we are not in the context of the realm (e.g. a page refresh on the /account page)
  // otherwise the skeleton will always show without changing to an actual state/value
  if (!inRealmContext) {
    return <></>;
  }

  return (
    <div className='flex justify-center'>
      {!loaded && <Skeleton className='w-24 h-6 rounded-md' />}
      {loaded && badge && badge.content && (
        <button
          className={classNames('py-1 px-2 border rounded text-sm', {
            'bg-orange-50 text-orange-500 border-orange-500': badge.type === 'default',
            'bg-red-50 text-red-500 border-red-500': badge.type === 'danger',
          })}
          onClick={() => navigate(`/realm/${realm?.publicKey}/admin/billing`)}
        >
          {badge.content}
        </button>
      )}
    </div>
  );
}

export default RealmStatusBadge;
