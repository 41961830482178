import classNames from 'classnames';
import React, { memo, useEffect, useState } from 'react';
import { useBannerContext } from 'context/BannerContext';
import useListRealmsModal from 'hooks/realm/UseListRealmsModal';
import useRealm from 'hooks/realm/UseRealm';
import useMenu, { MenuMode } from 'hooks/UseMenu';
import useUser from 'hooks/useUser';
import maxLength from 'util/MaxLength';
import CrossFadeImage from '../CrossFadeImage';
import RealmSwitcher from '../Realm/RealmSwitcher';
import Menu from './Menu';
import RealmStatusBadge from './RealmStatusBadge';
import { CaretDown, IconContext, List, TextOutdent } from '@phosphor-icons/react';
import useIsMobileDevice from 'hooks/UseIsMobileDevice';

interface Props {
  mainRef: React.RefObject<HTMLElement | null>;
}

function SidebarLeft({ mainRef }: Props): JSX.Element {
  const [logoOrientation, setLogoOrientation] = useState<'landscape' | 'portrait'>('portrait');

  const { isOpen, setIsOpen, menuMode } = useMenu();
  const { superAdminMode } = useUser();
  const { realm, realms, realmLogo } = useRealm();
  const { bannerIsVisible } = useBannerContext();
  const isMobileDevice = useIsMobileDevice();

  // Init the dialog for manage the user realms
  const { openModal: openManageRealmModal, dialog: manageRealmDialog } = useListRealmsModal();

  /**
   * Toggle the sidebar menu but only if the menu is in small mode
   */
  const toggleMenu = () => {
    if (menuMode === MenuMode.SMALL) {
      setIsOpen(prevState => !prevState);
    }
  };

  /**
   * When clicked outside, on the mainRef element: Close the sidebar when opened
   */
  useEffect(() => {
    const checkIfClickedOutside = (event: MouseEvent) => {
      if (menuMode === MenuMode.SMALL && isOpen && mainRef.current && event.target && mainRef.current.contains(event.target as Node)) {
        // If the menu is open and the clicked target is not within the menu,
        // then close the menu
        toggleMenu();
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [mainRef, isOpen]); //eslint-disable-line

  /**
   * Detect the Logo orientation
   */
  useEffect(() => {
    if (!realmLogo) return;

    const img = new Image();
    img.src = realmLogo;
    img.onload = () => setLogoOrientation(img.width > img.height ? 'landscape' : 'portrait');
  }, [realmLogo]);

  return (
    <>
      <aside
        className={classNames(
          'fixed bottom-0 top-0 bg-gray-100 border border-gray-200 w-[175px] 2xl:w-[210px] lg:translate-x-0 transition-[transform] z-[50]',
          {
            // We don't want to show the sidebar on mobile devices
            hidden: isMobileDevice,
            // We just show the menu icons
            'translate-x-[-130px] shadow-none': !isOpen,
            'translate-x-[0]': isOpen,
            'mt-6': bannerIsVisible,
          },
        )}
      >
        <button
          className={classNames('absolute block lg:hidden z-50 bg-gray-100 right-3 top-[6px]', {
            'mt-3': bannerIsVisible,
          })}
          onClick={toggleMenu}
        >
          <IconContext.Provider
            value={{
              size: 20,
              className: 'animate-fast text-gray-500',
            }}
          >
            {!isOpen && <List />}
            {isOpen && <TextOutdent />}
          </IconContext.Provider>
        </button>

        <div className='w-full h-full shadow-inner flex flex-col 2xl:pt-3 pt-1'>
          {isOpen ? (
            <>
              <div
                className={classNames('mx-auto px-2', {
                  'w-1/2': logoOrientation === 'portrait',
                  'w-3/4': logoOrientation === 'landscape',
                })}
              >
                {realmLogo && <CrossFadeImage src={realmLogo} alt='logo' />}
              </div>
              <RealmStatusBadge />
            </>
          ) : (
            <div className='flex justify-end translate-y-6 my-6 pr-2'>
              <img className='w-7 rounded' src={realmLogo} alt='logo' />
            </div>
          )}

          {/* We only show the realm switcher when the menu is fully open */}
          {isOpen && (
            <div className='mt-2 mx-3 py-2 border-gray-200 border-b text-gray-700'>
              {realms.length > 0 && !superAdminMode && (
                <RealmSwitcher onRealmSwitch={toggleMenu} includeCreateRealm={true}>
                  <div className='flex justify-center items-center text-center text-sm cursor-pointer'>
                    <strong className=''>{maxLength(realm ? realm.name : 'Select your realm', 18)}</strong>
                    <CaretDown
                      weight='fill'
                      className={classNames('ml-1', {
                        visible: realms.length > 1,
                      })}
                    />
                  </div>
                </RealmSwitcher>
              )}
              {realms.length > 0 && superAdminMode && (
                <button onClick={openManageRealmModal} className='flex w-full justify-center items-center text-center text-sm '>
                  <strong className=''>{maxLength(realm ? realm.name : 'Select your realm', 18)}</strong>
                  <CaretDown
                    weight='fill'
                    className={classNames('ml-1', {
                      visible: realms.length > 1,
                    })}
                  />
                </button>
              )}
            </div>
          )}

          <Menu />
        </div>

        {manageRealmDialog}
      </aside>
    </>
  );
}

export default memo(SidebarLeft);
