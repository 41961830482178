import React, { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useRealm from 'hooks/realm/UseRealm';
import { logger } from 'util/Logger';
import DropdownMenu, { DropdownMenuItemArray } from '../DropdownMenu';
import { Confirm } from '../Modal/ConfirmModal';
import useIsMobileDevice from 'hooks/UseIsMobileDevice';
import classNames from 'classnames';
import { Check, Plus } from '@phosphor-icons/react';
import { IS_IOS_APP } from 'const';
import { envVar } from 'util/Util/EnvVar';
import useUser from 'hooks/useUser';

interface Props {
  enabled?: boolean;
  children: ReactNode;
  includeCreateRealm: boolean;
  onRealmSwitch?: () => void;
}

function RealmSwitcher({ enabled = true, children, includeCreateRealm, onRealmSwitch }: Props): JSX.Element {
  const [dropdownItems, setDropdownItems] = useState<DropdownMenuItemArray[]>([]);

  const { realm, loadRealm, realms } = useRealm();
  const navigate = useNavigate();
  const isMobileDevice = useIsMobileDevice();
  const { user } = useUser();

  // detect if the user is an apple tester
  // if so, we will disable the create realm option
  const isAppleTester = IS_IOS_APP && envVar('APPLE_TEST_ACCOUNT') === user?.email;

  const navigateToRealm = async (realmId: number, publicKey: string) => {
    try {
      await loadRealm(realmId);
      navigate(`/realm/${publicKey}`);
      onRealmSwitch?.();
    } catch (e) {
      toast.error('Cannot load this realm. Please try again later.');
      logger.error(e);
    }
  };

  /**
   * Add Footer items to the dropDown items
   */
  useEffect(() => {
    if (realms.length === 0 || !enabled) return;

    const realmDropdownItems: DropdownMenuItemArray[] = [
      [
        // Add a header element
        {
          element: <p className='select-none'>Switch Realm</p>,
          isHeader: true,
        },
        // Add the realms as options
        ...realms.map(realmListItem => ({
          element: (
            <div className='flex items-center relative justify-center gap-x-2 md:justify-between  '>
              <span className={classNames({ 'font-semibold': realm?.id === realmListItem.id })}>{realmListItem.name}</span>
              {realm?.id === realmListItem.id && !isMobileDevice && <Check className='text-primary' />}
            </div>
          ),
          onClick: () => {
            // we only show the confirm modal if the user has more than 1 realm.
            // as we do not want to show the confirm modal when the user has just one
            if (realms.length > 1) {
              Confirm({
                id: 'switch-realm',
                isSkippable: true,
                title: 'Your are switching realms',
                text: (
                  <>
                    Are you sure you want to switch to the realm <strong>{realmListItem.name}</strong>
                  </>
                ),
                confirmText: 'Switch realm',
                onConfirm: () => navigateToRealm(realmListItem.id, realmListItem.publicKey),
              });
            } else {
              navigateToRealm(realmListItem.id, realmListItem.publicKey);
            }
          },
        })),
      ],
    ];

    // Add the footer items
    if (!isAppleTester && includeCreateRealm && (realm?.isAdmin || realm?.canCreateRealmByRealmUsers)) {
      realmDropdownItems.push([
        {
          element: (
            <div className='flex items-center relative justify-between'>
              Create Realm
              <Plus className='text-primary' />
            </div>
          ),
          onClick: () => navigate('/realm/create'),
        },
      ]);
    }

    setDropdownItems(realmDropdownItems);
  }, [realm, realms, includeCreateRealm, enabled]); //eslint-disable-line

  if (!enabled) {
    return <>{children}</>;
  }

  return (
    <DropdownMenu buttonClassName='w-full' className='min-w-[225px]' menuPlacement='bottom' paddingSize='sm' menuItems={dropdownItems}>
      {children}
    </DropdownMenu>
  );
}

export default RealmSwitcher;
